import React, { useEffect } from "react";
import baseUrl from "./baseUrl";

const RefreshToken = ({ connected, setConnected }) => {
  async function refreshToken() {
    try {
      let res = await fetch(`${baseUrl}/wagmilabs/refresh`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refreshToken: localStorage.refreshWebToken }),
      });
      res = await res.json();

      if (!res.authenticated) {
        localStorage.removeItem("jsonwebtoken");
        window.location.href = "/";
        setConnected(false);
      }
      localStorage.setItem("jsonwebtoken", res.token);
    } catch (err) {
      console.error(err);
    }
  }
  useEffect(() => {
    const interval = setInterval(refreshToken, 5000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected]);

  return <></>;
};

export default RefreshToken;
