import React from "react";
import helpDark from "../../../images/helpDark.svg";
import helpLight from "../../../images/helpLight.svg";
import question from "../../../images/question.png";

const EthereumTx = ({
  dropdownActive,
  displayTxs,
  toolsSelected,
  singleTxActive,
  checkTheBox,
  nftName,
  loading,
  setLoading,
  clearAll,
  setClearButton1Active,
  setClearButton2Active,
  setClearButton3Active,
  setClearButton4Active,
  txs,
  setDisplayTxs,
  theme,
  singleToolSelected,
  fullPage,
}) => {
  function filterTxs(e) {
    const text = e.target.value.toLocaleLowerCase();
    const filteredTx = txs.filter(tx => {
      if (tx.name) {
        const name = tx.name.toString().toLocaleLowerCase();
        const type = tx.txType.toString().toLocaleLowerCase();
        if (name.includes(text) || type.includes(text)) return tx;
      }
      return false;
    });
    setDisplayTxs(filteredTx);
  }

  // check if at least one tool is active
  let toolActive = false;
  Object.keys(toolsSelected).forEach(toolName => {
    if (toolsSelected[toolName] || !fullPage) {
      toolActive = true;
    }
  });

  if (toolActive) {
    const txList =
      dropdownActive &&
      displayTxs &&
      displayTxs.map(tx => {
        let type = tx.txType;
        let value = 0;
        let gasFee = 0;
        let isBuy = tx.isBuy;
        let sellPrice;
        const { name, image, creatorFee } = tx;

        if (!isBuy) {
          value = tx.buyPrice;
          sellPrice = tx.trade_price;
          gasFee = tx.buyGas;
        } else {
          value = tx.trade_price;
          sellPrice = tx.sellPrice;
          gasFee = tx.gas_fee;
        }

        function fill() {
          const nftData = { image, name };
          nftName(nftData);

          clearAll();

          if (fullPage) {
            Object.keys(toolsSelected).forEach(toolName => {
              if (toolsSelected[toolName]) {
                let fNameMap = {
                  breakEvenCalculator: 1,
                  profitCalculator: 2,
                  pnlCalculator: 3,
                  netSaleCalculator: 4,
                };

                const functionNumber = fNameMap[toolName];

                if (functionNumber === 1) {
                  setClearButton1Active(true);
                } else if (functionNumber === 2) {
                  setClearButton2Active(true);
                } else if (functionNumber === 3) {
                  setClearButton3Active(true);
                } else if (functionNumber === 4) {
                  setClearButton4Active(true);
                }

                const num1 = document.querySelector(`#n1f${functionNumber}`);
                const num2 = document.querySelector(`#n2f${functionNumber}`);
                const num3 = document.querySelector(`#n3f${functionNumber}`);
                const num4 = document.querySelector(`#n4f${functionNumber}`);
                const num5 = document.querySelector(`#n5f${functionNumber}`);
                const num6 = document.querySelector(`#n6f${functionNumber}`);

                if (!singleTxActive) {
                  if (functionNumber === 1 || functionNumber === 2) {
                    num1.value = tx.amount;
                    num2.value = value;
                    num3.value = gasFee;
                    num4.value = tx.approvalFee;
                    num5.value = creatorFee / 100;
                  } else if (functionNumber === 3) {
                    num1.value = sellPrice;
                    num2.value = tx.amount;
                    num3.value = value;
                    num4.value = gasFee;
                    num5.value = tx.approvalFee;
                    num6.value = creatorFee / 100;
                  } else if (functionNumber === 4) {
                    num2.value = creatorFee / 100;
                  }
                } else if (singleTxActive) {
                  if (functionNumber === 1) {
                    num1.value = 1;
                    num2.value = value;
                    num3.value = gasFee / tx.amount;
                    num4.value = tx.approvalFee / tx.amount;
                    num5.value = creatorFee / 100;
                  } else if (functionNumber === 2) {
                    num1.value = 1;
                    num2.value = value;
                    num3.value = gasFee / tx.amount;
                    num4.value = tx.approvalFee / tx.amount;
                    num5.value = creatorFee / 100;
                    // num6.value = sellPrice
                  } else if (functionNumber === 3) {
                    num1.value = tx.sumValue || sellPrice;
                    num2.value = 1;
                    num3.value = value;
                    num4.value = gasFee / tx.amount;
                    num5.value = tx.approvalFee / tx.amount;
                    num6.value = creatorFee / 100;
                  } else if (functionNumber === 4) {
                    num2.value = creatorFee / 100;
                  }
                }
              } else return;
            });
          } else {
            const functionMapping = {
              tool1: 1,
              tool2: 2,
              tool3: 3,
              tool4: 4,
            };
            const functionNumber = functionMapping[singleToolSelected];

            if (functionNumber === 1) {
              setClearButton1Active(true);
            } else if (functionNumber === 2) {
              setClearButton2Active(true);
            } else if (functionNumber === 3) {
              setClearButton3Active(true);
            } else if (functionNumber === 4) {
              setClearButton4Active(true);
            }

            const num1 = document.querySelector(`#n1f${functionNumber}`);
            const num2 = document.querySelector(`#n2f${functionNumber}`);
            const num3 = document.querySelector(`#n3f${functionNumber}`);
            const num4 = document.querySelector(`#n4f${functionNumber}`);
            const num5 = document.querySelector(`#n5f${functionNumber}`);
            const num6 = document.querySelector(`#n6f${functionNumber}`);

            if (!singleTxActive) {
              if (functionNumber === 1 || functionNumber === 2) {
                num1.value = tx.amount;
                num2.value = value;
                num3.value = gasFee;
                num4.value = tx.approvalFee;
                num5.value = creatorFee / 100;
              } else if (functionNumber === 3) {
                console.log(tx);
                num1.value = tx.sumValue || sellPrice;
                num2.value = tx.amount;
                num3.value = value;
                num4.value = gasFee;
                num5.value = tx.approvalFee;
                num6.value = creatorFee / 100;
              } else if (functionNumber === 4) {
                num2.value = creatorFee / 100;
              }
            } else if (singleTxActive) {
              if (functionNumber === 1) {
                num1.value = 1;
                num2.value = value;
                num3.value = gasFee / tx.amount;
                num4.value = tx.approvalFee / tx.amount;
                num5.value = creatorFee / 100;
              } else if (functionNumber === 2) {
                num1.value = 1;
                num2.value = value;
                num3.value = gasFee / tx.amount;
                num4.value = tx.approvalFee / tx.amount;
                num5.value = creatorFee / 100;
                // num6.value = sellPrice
              } else if (functionNumber === 3) {
                num1.value = sellPrice;
                num2.value = 1;
                num3.value = value;
                num4.value = gasFee / tx.amount;
                num5.value = tx.approvalFee / tx.amount;
                num6.value = creatorFee / 100;
              } else if (functionNumber === 4) {
                num2.value = creatorFee / 100;
              }
            }
          }
        }

        // give borders to batch transactions
        const borders = () => {
          if (tx.amount > 1 && !singleTxActive) {
            if (tx.txPosition === "first") {
              return { borderTop: "2px solid red", borderLeft: "2px solid red", borderRight: "2px solid red" };
            } else if (tx.txPosition === "middle") {
              return { borderLeft: "2px solid red", borderRight: "2px solid red" };
            } else if (tx.txPosition === "last") {
              return { borderBottom: "2px solid red", borderLeft: "2px solid red", borderRight: "2px solid red" };
            }
          } else {
            return {};
          }
        };

        setLoading(false);

        return (
          <>
            <div onClick={fill} className="single-nft-tx" style={borders()}>
              <img src={image ? image : question} alt="" className={image ? "" : "questionImage"} />

              <div>
                <p>{type}</p>

                {name}
              </div>
            </div>
          </>
        );
      });
    return (
      <>
        {loading && <div className="loading-trades">Loading trades...</div>}
        {!loading && txList && (
          <div className="sticky-transaction">
            <div className="single-transaction" onClick={e => checkTheBox(e)}>
              {/* <input type="text" style={{width: "100%"}}/> */}
              Isolate transactions
              <input
                type="checkbox"
                className="tx-checkbox"
                defaultChecked={singleTxActive ? true : false}
                onClick={() => checkTheBox(null)}
              />
              <a href="https://wagmilabshelp.gitbook.io/profit-calc-instructions/" rel="noreferrer" target="_blank">
                <img src={theme === "dark" ? helpDark : helpLight} className="help-icon" alt="" />
              </a>
            </div>

            <div className="nft-search-bar-container">
              <input
                type="text"
                placeholder="Search by name/type"
                className="nft-search-bar"
                onChange={e => filterTxs(e)}
              />
            </div>
            {displayTxs.length < 1 && <div className="no-tx-found">No transactions found...</div>}
          </div>
        )}
        {!loading && txList}
      </>
    );
  } else {
    return (
      <>
        {loading && <div className="loading-trades">Loading trades...</div>}
        {!loading && <>No tool selected</>}
      </>
    );
  }
};

export default EthereumTx;
