import React, { useEffect } from "react";
import fullPageImg from "../../../images/fullPageImg.svg";
import singlePageImg from "../../../images/singlePageImg.svg";
import { EthereumResult } from "../../EthereumResult";

const Options = ({
  blockchain,
  setBlockchain,
  theme,
  setTheme,
  setRoyalties,
  marketplace,
  setMarketplace,
  setFullPage,
  fullPage,
  modalLayout,
  ethPrice,
  setEthPrice,
  currencyType,
  setCurrencyType,
}) => {
  useEffect(() => {
    const marketplace = document.querySelector("#marketplace");
    const currentMarketplaceOptions = document.querySelectorAll("#marketplace option");

    currentMarketplaceOptions.forEach(option => option.remove());

    const marketplaceEthOptions = {
      Opensea: [
        "openseaEth | Opensea",
        "looksrare | LooksRare",
        "x2y2 | x2y2",
        "sudoswap | SudoSwap",
        "element | Element",
        "cryptopunks | CryptoPunks",
        "ensvision | Ens Vision",
      ],
      LooksRare: [
        "looksrare | LooksRare",
        "opensea | Opensea",
        "x2y2 | x2y2",
        "sudoswap | SudoSwap",
        "element | Element",
        "cryptopunks | CryptoPunks",
        "ensvision | Ens Vision",
      ],
      x2y2: [
        "x2y2 | x2y2",
        "opensea | Opensea",
        "looksrare | LooksRare",
        "sudoswap | SudoSwap",
        "element | Element",
        "cryptopunks | CryptoPunks",
        "ensvision | Ens Vision",
      ],
      SudoSwap: [
        "sudoswap | SudoSwap",
        "opensea | Opensea",
        "looksrare | LooksRare",
        "x2y2 | x2y2",
        "element | Element",
        "cryptopunks | CryptoPunks",
        "ensvision | Ens Vision",
      ],
      Element: [
        "element | Element",
        "opensea | Opensea",
        "looksrare | LooksRare",
        "x2y2 | x2y2",
        "sudoswap | SudoSwap",
        "cryptopunks | CryptoPunks",
        "ensvision | Ens Vision",
      ],
      CryptoPunks: [
        "cryptopunks | CryptoPunks",
        "opensea | Opensea",
        "looksrare | LooksRare",
        "x2y2 | x2y2",
        "sudoswap | SudoSwap",
        "element | Element",
        "ensvision | Ens Vision",
      ],
      "Ens Vision": [
        "ensvision | Ens Vision",
        "opensea | Opensea",
        "looksrare | LooksRare",
        "x2y2 | x2y2",
        "sudoswap | SudoSwap",
        "element | Element",
        "cryptopunks | CryptoPunks",
      ],
    };

    const marketplaceSolOptions = {
      Opensea: [
        "openseaSol | Opensea",
        "magiceden | Magic Eden",
        "fractal | Fractal",
        "yaww | Yaww",
        "hyperspace | Hyperspace",
        "solanart | Solanart",
        "hadeswap | Hadeswap",
      ],
      "Magic Eden": [
        "magiceden | Magic Eden",
        "opensea | Opensea",
        "fractal | Fractal",
        "yaww | Yaww",
        "hyperspace | Hyperspace",
        "solanart | Solanart",
        "hadeswap | Hadeswap",
      ],
      Fractal: [
        "fractal | Fractal",
        "magiceden | Magic Eden",
        "opensea | Opensea",
        "yaww | Yaww",
        "hyperspace | Hyperspace",
        "solanart | Solanart",
        "hadeswap | Hadeswap",
      ],
      Yaww: [
        "yaww | Yaww",
        "opensea | Opensea",
        "magiceden | Magic Eden",
        "fractal | Fractal",
        "hyperspace | Hyperspace",
        "solanart | Solanart",
        "hadeswap | Hadeswap",
      ],
      Hyperspace: [
        "hyperspace | Hyperspace",
        "opensea | Opensea",
        "magiceden | Magic Eden",
        "fractal | Fractal",
        "yaww | Yaww",
        "solanart | Solanart",
        "hadeswap | Hadeswap",
      ],
      Solanart: [
        "solanart | Solanart",
        "opensea | Opensea",
        "magiceden | Magic Eden",
        "fractal | Fractal",
        "yaww | Yaww",
        "hyperspace | Hyperspace",
        "hadeswap | Hadeswap",
      ],
      Hadeswap: [
        "hadeswap | Hadeswap",
        "opensea | Opensea",
        "magiceden | Magic Eden",
        "fractal | Fractal",
        "yaww | Yaww",
        "hyperspace | Hyperspace",
        "solanart | Solanart",
      ],
    };

    let marketplaceOptions;

    if (blockchain === "ethereum") {
      if (localStorage.marketplaceEth === undefined) {
        marketplaceOptions = marketplaceEthOptions["Opensea"];
      } else {
        marketplaceOptions = marketplaceEthOptions[localStorage.marketplaceEth];
      }
    } else {
      if (localStorage.marketplaceSol === undefined) {
        marketplaceOptions = marketplaceSolOptions["Opensea"];
        setRoyalties(2.5);
      } else {
        marketplaceOptions = marketplaceSolOptions[localStorage.marketplaceSol];
      }
    }

    marketplaceOptions.forEach(option => {
      let pair = option.split("|");
      let newOption = document.createElement("option");
      newOption.value = pair[0];
      newOption.innerHTML = pair[1];
      marketplace.options.add(newOption);
    });
    console.log(marketplaceOptions);
    setMarketplace(marketplaceOptions[0].split("|")[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockchain]);

  useEffect(() => {
    const marketplace = document.querySelector("#marketplace");

    switch (marketplace.options[marketplace.selectedIndex].text) {
      case "Opensea":
        setRoyalties(2.5);
        break;
      case "LooksRare":
        setRoyalties(2);
        break;
      case "x2y2":
        setRoyalties(0.5);
        break;
      case "SudoSwap":
        setRoyalties(0.5);
        break;
      case "Element":
        setRoyalties(2);
        break;
      case "CryptoPunks":
        setRoyalties(0);
        break;
      case "Ens Vision":
        setRoyalties(1.5);
        break;
      case "Magic Eden":
        setRoyalties(2);
        break;
      case "Fractal":
        setRoyalties(2);
        break;
      case "Yaww":
        setRoyalties(1);
        break;
      case "Hyperspace":
        setRoyalties(1);
        break;
      case "Solanart":
        setRoyalties(0);
        break;
      case "Hadeswap":
        setRoyalties(0);
        break;
      default:
        break;
    }

    if (blockchain === "ethereum") {
      localStorage.setItem("marketplaceEth", marketplace.options[marketplace.selectedIndex].text);
    } else if (blockchain === "solana") {
      localStorage.setItem("marketplaceSol", marketplace.options[marketplace.selectedIndex].text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplace]);

  return (
    <>
      <div className="div-marketplace-blockchain">
        <div style={{ display: "flex" }}>
          <div className="block">
            Blockchain:
            <select
              name="blockchain"
              id="blockchain"
              onChange={() => setBlockchain(blockchain === "ethereum" ? "solana" : "ethereum")}
            >
              <option value="ethereum">Eth</option>
              <option value="solana">Sol</option>
            </select>
          </div>

          <div className="market">
            Marketplace:
            <select
              name="marketplace"
              id="marketplace"
              onChange={() =>
                setMarketplace(
                  document.getElementById("marketplace").options[document.getElementById("marketplace").selectedIndex]
                    .value
                )
              }
            />
          </div>

          {(modalLayout && (
            <EthereumResult
              ethPrice={ethPrice}
              setEthPrice={setEthPrice}
              currencyType={currencyType}
              setCurrencyType={setCurrencyType}
            />
          )) ||
            ""}
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="full-page-option"
            onClick={() => {
              localStorage.setItem("fullPage", fullPage ? false : true);
              setFullPage(fullPage => !fullPage);
            }}
          >
            {fullPage ? (
              <div className="full-page-img-container">
                <img src={fullPageImg} alt="" className="full-page-img" />
                Full page
              </div>
            ) : (
              <div className="full-page-img-container">
                <img src={singlePageImg} alt="" className="full-page-img" />
                Single page
              </div>
            )}
          </div>

          <div className="mode-image">
            <img
              id="sun-moon-img"
              alt=""
              className="sun-img"
              onClick={() => {
                localStorage.setItem("theme", theme === "dark" ? "light" : "dark");
                setTheme(theme === "dark" ? "light" : "dark");
              }}
            />
            <p className="mode">{theme === "dark" ? "Dark mode" : "Light mode"}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Options;
