import React, { useEffect } from "react";
import ethLogo from "../../images/ethereum.png";

export const EthereumResult = ({ ethPrice, setEthPrice, currencyType, setCurrencyType }) => {
  useEffect(() => {
    async function price() {
      let fetchedEthPrice = await fetch(
        `https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=${currencyType}`
      );
      fetchedEthPrice = await fetchedEthPrice.json();
      fetchedEthPrice = Math.round(fetchedEthPrice.ethereum[currencyType]);
      setEthPrice(fetchedEthPrice);
    }
    price();
  }, [currencyType, setEthPrice]);

  return (
    <>
      <div className="eth-coin-price">
        <img className="eth-img" src={ethLogo} alt="" />
        <div className="eth-things">
          <div>
            <div className="ethereum-result">
              {currencyType === "usd" ? "$" : "€"} {ethPrice}
            </div>
            <div className="ethereum">Ethereum</div>
            <span className="select-currency-text">Currency:</span>
          </div>
          <select
            className="eth-value-selector"
            onChange={e => {
              setCurrencyType(e.target.options[e.target.selectedIndex].value);
            }}
            value={currencyType}
          >
            <option value="usd" className="usdSelector">
              USD
            </option>
            <option value="eur" className="eurSelector">
              EUR
            </option>
          </select>
        </div>
      </div>
    </>
  );
};
