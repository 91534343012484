import React, { useEffect, useState } from "react";
import baseUrl from "../../baseUrl";
import ClipboardJS from "clipboard";
import Loading from "../Loading";

const Tool1 = ({
  blockchain,
  royalties,
  clearButtonActive,
  setClearButtonActive,
  fullPage,
  singleToolSelected,
  marketplace,
}) => {
  const [calc, setCalc] = useState(0);
  const [loading, setLoading] = useState(false);

  let resultText = `<p class="text-result">`;
  let resultTextClose = `</p>`;
  let copyHtml1 = `<div id="copyfield"> <span class="copyvalue" id="function1">`;
  let copyHtml2 = `</span> <span class="copy-btn" id="function1">Copy</span> </div>`;
  let copyHtml3 = `<div id="copyfield"> <span class="copyvalue-2" id="function1">`;
  let copyHtml4 = `</span> <span class="copy-btn-2" id="function1">Copy</span> </div>`;

  function copyBtn(e, value) {
    e.target.setAttribute("data-clipboard-text", value);
    if (e.target.classList.contains("copy-btn-2")) {
      new ClipboardJS(`.copy-btn-2[id="function1"]`);
    } else {
      new ClipboardJS(`.copy-btn[id="function1"]`);
    }

    e.target.innerText = "Copied";
    setTimeout(() => (e.target.innerText = "Copy"), 1000);
  }

  useEffect(() => {
    const result = document.querySelector("#result1");
    const num1 = document.querySelector("#n1f1").value;
    const num2 = document.querySelector("#n2f1").value;
    const num3 = document.querySelector("#n3f1").value;
    const num4 = document.querySelector("#n4f1").value;
    const num5 = document.querySelector("#n5f1").value;

    if (calc > 0) {
      calculate();
    }
    async function calculate() {
      if (blockchain === "ethereum") {
        if (num1.length < 1 || num2.length < 1 || num3.length < 1 || num4.length < 1 || num5.length < 1) {
          result.innerText = "Please fill all the fields.";
          setTimeout(() => (result.innerText = ""), 1000);
          return;
        }
      } else if (blockchain === "solana") {
        if (num1.length < 1 || num2.length < 1 || num5.length < 1) {
          result.innerText = "Please fill all the fields.";
          setTimeout(() => (result.innerText = ""), 1000);
          return;
        }
      }

      if (blockchain === "ethereum") {
        const options = {
          num1,
          num2,
          num3,
          num4,
          num5,
          royalties,
          type: "ethereum",
        };
        try {
          setLoading(true);
          let calculation = await fetch(`${baseUrl}/api.wagmilabs/api/calculation1`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": localStorage.jsonwebtoken,
            },
            body: JSON.stringify(options),
          });
          calculation = await calculation.json();

          setLoading(false);
          if (calculation.authenticated === false) {
            result.innerHTML =
              "An error occurred while fetching data, please check your internet connection and retry.";
          } else {
            const copyValue = calculation.calculation;
            calculation = calculation.calculation;

            if (num1 <= 1) {
              calculation = `<span class="eth-number">${calculation}</span>`;
              result.innerHTML = `${resultText}To break even you need to list your NFT at ${calculation} ETH${resultTextClose}${copyHtml1}${calculation}${copyHtml2}`;

              const copyBtnElement = document.querySelector(`.copy-btn[id="function1"]`);
              copyBtnElement.addEventListener("click", e => copyBtn(e, copyValue, 1));
            } else if (num1 > 1) {
              let otherresult = `<span class="eth-number">${
                Math.ceil((calculation / num1) * 100000) / 100000.0
              }</span>`;
              const copyValue2 = Math.ceil((calculation / num1) * 100000) / 100000.0;

              calculation = `<span class="eth-number">${calculation}</span>`;
              result.innerHTML = `${resultText}To break even, you need to list EACH of your ${num1} NFTs at ${otherresult} ETH, or list them in a bundle at ${calculation} ETH${resultTextClose}${copyHtml1}${otherresult}${copyHtml2}${copyHtml3}${calculation}${copyHtml4}`;

              const copyBtnElement = document.querySelector(`.copy-btn[id="function1"]`);
              const copyBtn2Element = document.querySelector(`.copy-btn-2[id="function1"]`);
              copyBtnElement.addEventListener("click", e => copyBtn(e, copyValue2));
              copyBtn2Element.addEventListener("click", e => copyBtn(e, copyValue));
            }
          }
        } catch (err) {
          result.innerHTML = "An error occurred while fetching data, please check your internet connection and retry.";
        }
      } else if (blockchain === "solana") {
        const options = {
          num1,
          num2,
          num5,
          royalties,
          type: "solana",
        };

        try {
          setLoading(true);
          let calculation = await fetch(`${baseUrl}/api.wagmilabs/api/calculation1`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": localStorage.jsonwebtoken,
            },
            body: JSON.stringify(options),
          });
          calculation = await calculation.json();
          setLoading(false);
          if (calculation.authenticated === false) {
            result.innerHTML =
              "An error occurred while fetching data, please check your internet connection and retry.";
          } else {
            const copyValue = calculation.calculation;
            calculation = calculation.calculation;

            if (num1 <= 1) {
              calculation = `<span class="sol-number">${calculation}</span>`;
              result.innerHTML = `${resultText}To break even you need to list your NFT at ${calculation} SOL${resultTextClose}${copyHtml1}${calculation}${copyHtml2}`;

              const copyBtnElement = document.querySelector(".copy-btn");
              copyBtnElement.addEventListener("click", e => copyBtn(e, copyValue, 1));
            } else if (num1 > 1) {
              let otherresult = `<span class="sol-number">${
                Math.ceil((calculation / num1) * 100000) / 100000.0
              }</span>`;
              const copyValue2 = Math.ceil((calculation / num1) * 100000) / 100000.0;

              calculation = `<span class="sol-number">${calculation}</span>`;
              result.innerHTML = `${resultText}To break even, you need to list EACH of your ${num1} NFTs at ${otherresult} SOL, or list them in a bundle at ${calculation} SOL${resultTextClose}${copyHtml1}${otherresult}${copyHtml2}${copyHtml3}${calculation}${copyHtml4}`;

              const copyBtnElement = document.querySelector(".copy-btn");
              const copyBtn2Element = document.querySelector(".copy-btn-2");
              copyBtnElement.addEventListener("click", e => copyBtn(e, copyValue2));
              copyBtn2Element.addEventListener("click", e => copyBtn(e, copyValue));
            }
          }
        } catch (err) {
          result.innerHTML = "An error occurred while fetching data, please check your internet connection and retry.";
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calc]);

  useEffect(() => {
    const result = document.querySelector("#result1");
    if (result.innerText.length > 10) {
      setCalc(calc + 1);
    }
    if (blockchain === "ethereum") {
      document.querySelector(".input-label-f1-hide").style.display = "inline";
      document.querySelector(".input-label-f1-hide2").style.display = "inline";
    } else if (blockchain === "solana") {
      document.querySelector(".input-label-f1-hide").style.display = "none";
      document.querySelector(".input-label-f1-hide2").style.display = "none";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplace]);

  function updateClearButton() {
    const num1 = document.querySelector(`#n1f1`);
    const num2 = document.querySelector(`#n2f1`);
    const num3 = document.querySelector(`#n3f1`);
    const num4 = document.querySelector(`#n4f1`);
    const num5 = document.querySelector(`#n5f1`);
    const result = document.querySelector(`#result1`);

    if (
      num1.value.length > 0 ||
      num2.value.length > 0 ||
      num3.value.length > 0 ||
      num4.value.length > 0 ||
      num5.value.length > 0 ||
      result.innerText.length > 0
    ) {
      setClearButtonActive(true);
    } else {
      setClearButtonActive(false);
    }
  }

  function clearTool() {
    document.querySelector(`#n1f1`).value = "";
    document.querySelector(`#n2f1`).value = "";
    document.querySelector(`#n3f1`).value = "";
    document.querySelector(`#n4f1`).value = "";
    document.querySelector(`#n5f1`).value = "";
    document.querySelector(`#result1`).innerHTML = "";
  }
  const position = () => {
    if (fullPage) {
      return "inline";
    } else {
      if (singleToolSelected === "tool1") {
        return "flex";
      } else {
        return "none";
      }
    }
  };

  const margin = () => {
    if (fullPage) {
      return "0";
    } else {
      if (singleToolSelected === "tool1") {
        return "80px";
      } else {
        return "0";
      }
    }
  };
  return (
    <div className={fullPage ? "" : "align-calculator"} style={{ display: position(), marginLeft: margin() }}>
      <div>
        <div className="input-label-container">
          <h1 className="calc-title calc-title1">Break even calculator</h1>

          <div className="input-label-f1">
            <input
              className="input-f1 input-f1-1"
              type="number"
              id="n1f1"
              placeholder=" "
              onChange={updateClearButton}
            />
            <label className="label-f1 label-f1-1" htmlFor="n1f1">
              N° of NFTs purchased
            </label>
          </div>

          <div className="input-label-f1">
            <input
              className="input-f1 input-f1-2"
              type="number"
              id="n2f1"
              placeholder=" "
              onChange={updateClearButton}
            />
            <label className="label-f1 label-f1-2" htmlFor="n2f1">
              Individual NFT cost
            </label>
          </div>

          <div className="input-label-f1-hide">
            <input
              className="input-f1 input-f1-3"
              type="number"
              id="n3f1"
              placeholder=" "
              onChange={updateClearButton}
            />
            <label className="label-f1 label-f1-3" htmlFor="n3f1">
              Gas fees (total)
            </label>
          </div>

          <div className="input-label-f1-hide2">
            <input
              className="input-f1 input-f1-4"
              type="number"
              id="n4f1"
              placeholder=" "
              onChange={updateClearButton}
            />
            <label className="label-f1 label-f1-4" htmlFor="n4f1">
              Approval cost
            </label>
          </div>

          <div className="input-label-f1">
            <input
              className="input-f1 input-f1-5"
              type="number"
              id="n5f1"
              placeholder=" "
              onChange={updateClearButton}
            />
            <label className="label-f1 label-f1-5" htmlFor="n5f1">
              Creator fees
            </label>
          </div>
        </div>

        <div className="button-flex">
          <button
            id="button1"
            onClick={() => {
              setCalc(calc + 1);
              updateClearButton();
            }}
          >
            CALCULATE
          </button>
          {clearButtonActive && (
            <button
              onClick={() => {
                clearTool();
                updateClearButton();
              }}
              className="clear-btn"
            >
              CLEAR
            </button>
          )}
        </div>

        <div className="result-name"> Result:</div>
        {loading && <Loading />}
        <div id="result1" className="calc-result1"></div>
      </div>
    </div>
  );
};

export default Tool1;
