import React, { useEffect } from "react";
import { EthereumResult } from "../EthereumResult";
// import baseUrl from '../baseUrl'
import wagmiLogoDark from "../../images/wagmiLogoDark.png";
import wagmiLogoLight from "../../images/wagmiLogoLight.png";
import solLogo from "../../images/solana.png";
import RefreshToken from "../RefreshToken";
import bottleLogo from "../../images/bottleLogo.png";
import "./header.css";

const Header = ({
  connected,
  setConnected,
  blockchain,
  theme,
  ethPrice,
  setEthPrice,
  solPrice,
  setSolPrice,
  currencyType,
  setCurrencyType,
  setBurger,
  burger,
  fullPage,
  singleToolSelected,
}) => {
  function logout() {
    localStorage.removeItem("jsonwebtoken");
    localStorage.removeItem("refreshWebToken");
    window.location.reload();
  }

  function disableScrolling() {
    document.getElementById("root").style.height = "100vh";
    document.getElementById("root").style.overflow = "hidden";
  }
  function enableScrolling() {
    document.getElementById("root").style.height = "100%";
    document.getElementById("root").style.overflow = "visible";
  }

  const toggleBurger = () => {
    window.scrollTo(0, 0);
    setBurger(prevState => !prevState);
  };

  const toolMapping = {
    tool1: "0",
    tool2: "1",
    tool3: "2",
    tool4: "3",
  };

  useEffect(() => {
    const burgerIcon = document.querySelector(".hamburger");
    const tools = document.querySelectorAll(".burger-tool-select");
    if (burger) {
      burgerIcon && burgerIcon.classList.add("is-active");
      tools && tools[toolMapping[singleToolSelected]].classList.add("is-active");
      disableScrolling();
    } else {
      burgerIcon && burgerIcon.classList.remove("is-active");
      enableScrolling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [burger]);

  return (
    <>
      <RefreshToken connected={connected} setConnected={setConnected} />
      <div className={theme} id="header-dark">
        <header className="header">
          <img
            className="wagmi-title"
            src={theme === "dark" ? wagmiLogoDark : wagmiLogoLight}
            alt=""
            onClick={() => (window.location.href = "/")}
          />

          {fullPage ? (
            <img src={bottleLogo} className="bottle-logo" alt="" />
          ) : (
            // burger &&
            <div className="hamburger burger" id="hamburger-1" onClick={toggleBurger}>
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>
          )}

          <h1 className="header-title">Profitability calculator</h1>

          <button className="connect" onClick={logout}>
            Log out
          </button>

          {blockchain === "ethereum" && (
            <EthereumResult
              ethPrice={ethPrice}
              setEthPrice={setEthPrice}
              currencyType={currencyType}
              setCurrencyType={setCurrencyType}
            />
          )}
          {blockchain === "solana" && (
            <SolResult
              solPrice={solPrice}
              setSolPrice={setSolPrice}
              currencyType={currencyType}
              setCurrencyType={setCurrencyType}
            />
          )}
        </header>
      </div>
    </>
  );
};

const SolResult = ({ solPrice, setSolPrice, currencyType, setCurrencyType }) => {
  useEffect(() => {
    const usdSelector = document.querySelector(".usdSelector");
    const eurSelector = document.querySelector(".eurSelector");

    currencyType === "usd" ? usdSelector.setAttribute("selected", true) : eurSelector.setAttribute("selected", true);

    async function price() {
      try {
        let fetchedSolPrice = await fetch(
          `https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=${currencyType}`
        );
        fetchedSolPrice = await fetchedSolPrice.json();
        fetchedSolPrice = Math.round(fetchedSolPrice.solana[currencyType]);
        setSolPrice(fetchedSolPrice);
      } catch (err) {
        setSolPrice("- - -");
      }
    }
    price();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyType]);

  return (
    <>
      <div className="sol-coin-price">
        <img className="eth-img" src={solLogo} alt="" />
        <div className="sol-things">
          <div className="solana-result">
            {currencyType === "usd" ? "$" : "€"} {solPrice}
          </div>
          <div className="solana">Solana</div>
          <select
            className="sol-value-selector"
            onChange={e => {
              setCurrencyType(e.target.options[e.target.selectedIndex].value);
            }}
          >
            <option value="usd" className="usdSelector">
              USD
            </option>
            <option value="eur" className="eurSelector">
              EUR
            </option>
          </select>
        </div>
      </div>
    </>
  );
};

export default Header;
