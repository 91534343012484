import React, { useState, useEffect } from "react";
import Login from "./components/login/LoginRainbow";
import Header from "./components/header/Header";
import Tools from "./components/tools/Tools";
import Checking from "./components/checking/Checking";
import LoadingHeader from "./components/checking/LoadingHeader";

function App() {
  const [burger, setBurger] = useState(false);
  const [connected, setConnected] = useState(false);
  const [checking, setChecking] = useState(false);
  const [blockchain, setBlockchain] = useState("ethereum");
  const [marketplace, setMarketplace] = useState("opensea");
  const [ethPrice, setEthPrice] = useState("Loading...");
  const [solPrice, setSolPrice] = useState("Loading...");
  const [royalties, setRoyalties] = useState(2.5);
  const [theme, setTheme] = useState(localStorage.theme === undefined ? "dark" : localStorage.theme);
  const [currencyType, setCurrencyType] = useState("usd");
  const params = new URLSearchParams(window?.location?.search || "");
  const modalLayout = !!params.get("modalLayout");
  const defaultFullPage = localStorage.fullPage === "true" ? true : false;
  const [fullPage, setFullPage] = useState(modalLayout ? false : defaultFullPage);
  const [singleToolSelected, setSingleToolSelected] = useState("tool1");
  const [loginEnabled, setLoginEnabled] = useState(false);

  useEffect(() => {
    setLoginEnabled(true);
    if (localStorage.jsonwebtoken || localStorage.refreshWebToken) {
      setChecking(true);
    }
  }, []);

  useEffect(() => {
    if (checking) {
      if (theme === "light") {
        document.body.style.background = "white";
      } else {
        document.body.style.background = "#060b23";
      }
    } else if (!connected) {
      document.body.style.background = "linear-gradient(to right, #3494E6, #EC6EAD)";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, checking]);

  return (
    <div id="page-wrapper" className={modalLayout ? "modal-layout" : ""}>
      {connected ? (
        <>
          <Header
            burger={burger}
            connected={connected}
            setConnected={setConnected}
            blockchain={blockchain}
            setEthPrice={setEthPrice}
            setSolPrice={setSolPrice}
            theme={theme}
            ethPrice={ethPrice}
            solPrice={solPrice}
            currencyType={currencyType}
            setCurrencyType={setCurrencyType}
            setBurger={setBurger}
            fullPage={fullPage}
            singleToolSelected={singleToolSelected}
          />

          <Tools
            setMarketplace={setMarketplace}
            setBlockchain={setBlockchain}
            setRoyalties={setRoyalties}
            marketplace={marketplace}
            blockchain={blockchain}
            royalties={royalties}
            ethPrice={ethPrice}
            solPrice={solPrice}
            theme={theme}
            setTheme={setTheme}
            currencyType={currencyType}
            setBurger={setBurger}
            burger={burger}
            fullPage={fullPage}
            setFullPage={setFullPage}
            singleToolSelected={singleToolSelected}
            setSingleToolSelected={setSingleToolSelected}
            modalLayout={modalLayout}
            setEthPrice={setEthPrice}
            setCurrencyType={setCurrencyType}
          />
        </>
      ) : checking ? (
        <>
          <LoadingHeader theme={theme} />
          <Checking setConnected={setConnected} setChecking={setChecking} theme={theme} modalLayout={modalLayout} />
        </>
      ) : (
        <>{(loginEnabled && <Login setConnected={setConnected} />) || ""}</>
      )}
    </div>
  );
}

export default App;
