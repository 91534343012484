import React, { useEffect, useState } from "react";
import baseUrl from "../../baseUrl";
import Loading from "../Loading";

const Tool3 = ({
  blockchain,
  solPrice,
  ethPrice,
  royalties,
  currencyType,
  clearButtonActive,
  setClearButtonActive,
  fullPage,
  singleToolSelected,
  marketplace,
}) => {
  const [calc, setCalc] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const result = document.querySelector("#result3");
    const num1 = document.querySelector("#n1f3").value;
    const num2 = document.querySelector("#n2f3").value;
    const num3 = document.querySelector("#n3f3").value;
    const num4 = document.querySelector("#n4f3").value;
    const num5 = document.querySelector("#n5f3").value;
    const num6 = document.querySelector("#n6f3").value;

    if (calc > 0) {
      calculate();
    }
    async function calculate() {
      if (blockchain === "ethereum") {
        if (
          num1.length < 1 ||
          num2.length < 1 ||
          num3.length < 1 ||
          num4.length < 1 ||
          num5.length < 1 ||
          num6.length < 1
        ) {
          result.innerText = "Please fill all the fields.";
          setTimeout(() => (result.innerText = ""), 1000);
          return;
        }
      } else if (blockchain === "solana") {
        if (num1.length < 1 || num2.length < 1 || num3.length < 1 || num6.length < 1) {
          result.innerText = "Please fill all the fields.";
          setTimeout(() => (result.innerText = ""), 1000);
          return;
        }
      }

      const currency = currencyType === "usd" ? "$" : "€";

      if (blockchain === "ethereum") {
        const options = {
          num1,
          num2,
          num3,
          num4,
          num5,
          num6,
          royalties,
          solPrice,
          ethPrice,
          type: "ethereum",
        };

        try {
          setLoading(true);
          let calculationResult = await fetch(`${baseUrl}/api.wagmilabs/api/calculation3`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": localStorage.jsonwebtoken,
            },
            body: JSON.stringify(options),
          });
          calculationResult = await calculationResult.json();

          setLoading(false);
          if (calculationResult.authenticated === false) {
            result.innerHTML =
              "An error occurred while fetching data, please check your internet connection and retry.";
          } else {
            let single = calculationResult.single;
            let infiat = calculationResult.infiat;
            let calculation = calculationResult.calculation;

            if (calculation >= 0) {
              calculation = `<span class="profit-number">${calculation}</span>`;
              if (num2 <= 1) {
                result.innerHTML = `Selling/sold your NFT at ${num1} ETH, total profits: ${calculation} ETH (${currency} ${infiat})`;
              } else if (num2 > 1) {
                result.innerHTML = `Selling/sold your ${num2} NFTs in a bundle at ${num1} ETH, or EACH of them at ${single}, total profits: ${calculation} ETH (${currency} ${infiat})`;
              }
            } else if (calculation < 0) {
              calculation = String(calculation);
              infiat = String(infiat);
              calculation = calculation.slice(1);
              infiat = infiat.slice(1);
              calculation = `<span class="loss-number">${calculation}</span>`;
              if (num2 <= 1) {
                result.innerHTML = `Selling/sold your NFT at ${num1} ETH, total losses: ${calculation} ETH (${currency} ${infiat})`;
              } else if (num2 > 1) {
                result.innerHTML = `Selling/sold your ${num2} NFTs in a bundle at ${num1} ETH, or EACH of them at ${single}, total losses: ${calculation} ETH (${currency} ${infiat})`;
              }
            }
          }
        } catch {
          result.innerHTML = "An error occurred while fetching data, please check your internet connection and retry.";
        }
      } else if (blockchain === "solana") {
        const options = {
          num1,
          num2,
          num3,
          num6,
          royalties,
          solPrice,
          ethPrice,
          type: "solana",
        };

        try {
          setLoading(true);
          let calculationResult = await fetch(`${baseUrl}/api.wagmilabs/api/calculation3`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": localStorage.jsonwebtoken,
            },
            body: JSON.stringify(options),
          });
          calculationResult = await calculationResult.json();

          setLoading(false);
          if (calculationResult.authenticated === false) {
            result.innerHTML =
              "An error occurred while fetching data, please check your internet connection and retry.";
          } else {
            let single = calculationResult.single;
            let infiat = calculationResult.infiat;
            let calculation = calculationResult.calculation;

            if (calculation >= 0) {
              calculation = `<span class="profit-number">${calculation}</span>`;
              if (num2 <= 1) {
                result.innerHTML = `Selling/sold your NFT at ${num1} SOL, total profits: ${calculation} SOL (${currency} ${infiat})`;
              } else if (num2 > 1) {
                result.innerHTML = `Selling/sold your ${num2} NFTs in a bundle at ${num1} SOL, or EACH of them at ${single}, total profits: ${calculation} SOL (${currency} ${infiat})`;
              }
            } else if (calculation < 0) {
              calculation = String(calculation);
              infiat = String(infiat);
              calculation = calculation.slice(1);
              infiat = infiat.slice(1);
              calculation = `<span class="loss-number">${calculation}</span>`;
              if (num2 <= 1) {
                result.innerHTML = `Selling/sold your NFT at ${num1} SOL, total losses: ${calculation} SOL (${currency} ${infiat})`;
              } else if (num2 > 1) {
                result.innerHTML = `Selling/sold your ${num2} NFTs in a bundle at ${num1} SOL, or EACH of them at ${single}, total losses: ${calculation} SOL (${currency} ${infiat})`;
              }
            }
          }
        } catch {
          result.innerHTML = "An error occurred while fetching data, please check your internet connection and retry.";
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calc]);

  useEffect(() => {
    const result = document.querySelector("#result3");
    if (result.innerText.length > 10) {
      setCalc(calc + 1);
    }
    if (blockchain === "ethereum") {
      document.querySelector(".input-label-f3-hide").style.display = "inline";
      document.querySelector(".input-label-f3-hide2").style.display = "inline";
    } else if (blockchain === "solana") {
      document.querySelector(".input-label-f3-hide").style.display = "none";
      document.querySelector(".input-label-f3-hide2").style.display = "none";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplace, currencyType]);

  function updateClearButton() {
    const num1 = document.querySelector(`#n1f3`);
    const num2 = document.querySelector(`#n2f3`);
    const num3 = document.querySelector(`#n3f3`);
    const num4 = document.querySelector(`#n4f3`);
    const num5 = document.querySelector(`#n5f3`);
    const num6 = document.querySelector(`#n6f3`);
    const result = document.querySelector(`#result3`);

    if (
      num1.value.length > 0 ||
      num2.value.length > 0 ||
      num3.value.length > 0 ||
      num4.value.length > 0 ||
      num5.value.length > 0 ||
      num6.value.length > 0 ||
      result.innerText.length > 0
    ) {
      setClearButtonActive(true);
    } else {
      setClearButtonActive(false);
    }
  }

  function clearTool() {
    document.querySelector(`#n1f3`).value = "";
    document.querySelector(`#n2f3`).value = "";
    document.querySelector(`#n3f3`).value = "";
    document.querySelector(`#n4f3`).value = "";
    document.querySelector(`#n5f3`).value = "";
    document.querySelector(`#n6f3`).value = "";
    document.querySelector(`#result3`).innerHTML = "";
  }

  const position = () => {
    if (fullPage) {
      return "inline";
    } else {
      if (singleToolSelected === "tool3") {
        return "flex";
      } else {
        return "none";
      }
    }
  };

  const margin = () => {
    if (fullPage) {
      return "0";
    } else {
      if (singleToolSelected === "tool3") {
        return "80px";
      } else {
        return "0";
      }
    }
  };

  return (
    <div className={fullPage ? "" : "align-calculator"} style={{ display: position(), marginLeft: margin() }}>
      <div>
        <div className="input-label-container">
          <h1 className="calc-title">P&L calculator</h1>

          <div className="input-label-f3">
            <input
              className="input-f3 input-f3-1"
              type="number"
              id="n2f3"
              placeholder=" "
              onChange={updateClearButton}
            />
            <label htmlFor="n2f3" className="label-f3 label-f3-1">
              N° of NFTs purchased
            </label>
          </div>

          <div className="input-label-f3">
            <input
              className="input-f3 input-f3-2"
              type="number"
              id="n3f3"
              placeholder=" "
              onChange={updateClearButton}
            />
            <label htmlFor="n3f3" className="label-f3 label-f3-2">
              Individual NFT cost
            </label>
          </div>

          <div className="input-label-f3-hide">
            <input
              className="input-f3 input-f3-3"
              type="number"
              id="n4f3"
              placeholder=" "
              onChange={updateClearButton}
            />
            <label htmlFor="n4f3" className="label-f3 label-f3-3">
              Gas cost (total)
            </label>
          </div>

          <div className="input-label-f3-hide2">
            <input
              className="input-f3 input-f3-4"
              type="number"
              id="n5f3"
              placeholder=" "
              onChange={updateClearButton}
            />
            <label htmlFor="n5f3" className="label-f3 label-f3-4">
              Approval cost
            </label>
          </div>

          <div className="input-label-f3">
            <input
              className="input-f3 input-f3-5"
              type="number"
              id="n6f3"
              placeholder=" "
              onChange={updateClearButton}
            />
            <label htmlFor="n6f3" className="label-f3 label-f3-5">
              Creator fees
            </label>
          </div>

          <div className="input-label-f3">
            <input
              className="input-f3 input-f3-6"
              type="number"
              id="n1f3"
              placeholder=" "
              onChange={updateClearButton}
            />
            <label htmlFor="n1f3" className="label-f3 label-f3-6">
              Listing/Sale price
            </label>
          </div>
        </div>

        <div className="button-flex">
          <button id="button3" onClick={() => setCalc(calc + 1)}>
            CALCULATE
          </button>
          {clearButtonActive && (
            <button
              onClick={() => {
                clearTool();
                updateClearButton();
              }}
              className="clear-btn"
            >
              CLEAR
            </button>
          )}
        </div>

        <div className="result-name"> Result:</div>
        {loading && <Loading />}
        <div id="result3" className="calc-result3"></div>
      </div>
    </div>
  );
};

export default Tool3;
