import React, { useState, useEffect, useRef } from "react";
import EthereumTx from "./EthereumTx";
// import SolanaTx from './SolanaTx'
import dropdown from "../../../images/dropdown.png";
import baseUrl from "../../baseUrl";
import developing from "../../../images/development.svg";

const Txs = ({
  fullPage,
  blockchain,
  setClearButton1Active,
  setClearButton2Active,
  setClearButton3Active,
  setClearButton4Active,
  theme,
  singleToolSelected,
}) => {
  const [solanaWallet, setSolanaWallet] = useState(null);
  const [solanaTrades, setSolanaTrades] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dropdownActive, setDropdownActive] = useState(false);
  const dropdownActiveRef = useRef(false);
  const [dropdown2Active, setDropdown2Active] = useState(false);
  const dropdown2ActiveRef = useRef(false);
  const [toolsSelected, setToolsSelected] = useState({
    breakEvenCalculator: true,
    profitCalculator: false,
    pnlCalculator: false,
    netSaleCalculator: false,
  });
  const [singleTxActive, setSingleTxActive] = useState(false);
  const [txs, setTxs] = useState(null);
  const [displayTxs, setDisplayTxs] = useState(null);

  useEffect(() => {
    window.addEventListener("click", e => {
      let contains = undefined;
      const path = e.composedPath();
      path.forEach(el => {
        if (el === document.querySelector(".txs-select")) contains = "drop1";
        else if (el === document.querySelector(".calculators-select")) contains = "drop2";
      });

      if (dropdownActiveRef.current && contains !== "drop1") {
        dropdownActiveRef.current = false;
        document.querySelector(".dropdown-img").classList.remove("rotate");
        setDropdownActive(false);
      } else if (dropdown2ActiveRef.current && contains !== "drop2") {
        dropdown2ActiveRef.current = false;
        document.querySelector(".dropdown-img-2").classList.remove("rotate");
        setDropdown2Active(false);
      }
    });
  }, []);

  const arrow1 = e => {
    if (
      e.target === document.querySelector(".single-transaction") ||
      e.target === document.querySelector(".single-transaction *") ||
      e.target === document.querySelector(".connect-phantom-button") ||
      e.target === document.querySelector(".nft-search-bar") ||
      e.target === document.querySelector(".help-icon")
    ) {
      return;
    }
    document.querySelector(".dropdown-img").classList.toggle("rotate");
    if (dropdownActive) {
      setDisplayTxs(txs);
    }
    dropdownActiveRef.current = !dropdownActive;
    setDropdownActive(!dropdownActive);
  };

  const arrow2 = e => {
    if (e.target === document.querySelector(".single-calculator-select")) {
      return;
    }
    let check = true;
    document.querySelectorAll(".single-calculator-select *").forEach(el => {
      if (el === e.target) {
        check = false;
      }
    });
    if (!check) {
      return;
    }
    document.querySelector(".dropdown-img-2").classList.toggle("rotate");
    dropdown2ActiveRef.current = !dropdown2Active;
    setDropdown2Active(!dropdown2Active);
  };

  const timer = ms => new Promise(res => setTimeout(res, ms));

  // fetch user transactions
  useEffect(() => {
    async function fetchTxs() {
      try {
        setLoading(true);
        let txs = await fetch(`${baseUrl}/api.wagmilabs/api/txs`, {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": localStorage.jsonwebtoken,
          },
        });
        txs = await txs.json();
        txs = txs.tradeTxs;

        let tokenIds = [];
        let contractAddresses = [];

        txs.forEach(tx => {
          const tokenId = tx.token_id;
          const contractAddress = tx.contract_address;
          tokenIds.push(`&token_ids=${tokenId}`);
          contractAddresses.push(`&asset_contract_addresses=${contractAddress}`);
        });

        const sortNumber = 20;

        const tokenIdsContainer = new Array(Math.ceil(tokenIds.length / sortNumber))
          .fill()
          .map(_ => tokenIds.splice(0, sortNumber));

        const contractAddressesContainer = new Array(Math.ceil(contractAddresses.length / sortNumber))
          .fill()
          .map(_ => contractAddresses.splice(0, sortNumber));

        let items = [];

        for (let i = 0; i < tokenIdsContainer.length; i++) {
          const tokenIds = tokenIdsContainer[i].join("");
          const contractAddresses = contractAddressesContainer[i].join("");

          try {
            let data = await fetch(
              `https://api.opensea.io/api/v1/assets?order_direction=asc&limit=50${tokenIds}${contractAddresses}`
            );
            data = await data.json();
            items.push(...data.assets);
          } catch (error) {
            console.error(error);
          }

          await timer(100);
        }

        txs.forEach(tx => {
          items.forEach(item => {
            if (item.token_id === tx.token_id && item.asset_contract?.address === tx.contract_address) {
              tx["name"] = item.name;
              tx["image"] = item.image_url;
              tx["creatorFee"] = item.asset_contract?.dev_seller_fee_basis_points;
            }
          });
        });
        setTxs(txs);
        setDisplayTxs(txs);
      } catch (err) {
        console.error(err);
      }
    }
    localStorage.getItem("jsonwebtoken") && fetchTxs();
  }, []);

  // check the box
  function checkTheBox(e) {
    if (e) {
      const checkbox = document.querySelector(".tx-checkbox");
      const helpIcon = document.querySelector(".help-icon");
      if (e.target === checkbox || e.target === helpIcon) {
        return;
      }
      const checked = checkbox.checked;
      checkbox.checked = !checked;
    }

    setSingleTxActive(oldTxActive => !oldTxActive);
  }

  // check boxes when open calculators
  useEffect(() => {
    function checkAll() {
      let selectedCount = 0;
      Object.keys(toolsSelected).forEach(toolName => {
        if (toolsSelected[toolName]) {
          selectedCount += 1;
        }
      });
      // console.log(selectedCount)
      if (selectedCount === 4) {
        document.querySelector(".all-checkbox").checked = true;
      } else {
        document.querySelector(".all-checkbox").checked = false;
      }
    }

    dropdown2Active && fullPage && checkAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdown2Active]);

  // set calculator active on click
  function setCalculator(e, direct) {
    if (fullPage) {
      let selectedCount = 0;
      Object.keys(toolsSelected).forEach(toolName => {
        if (toolsSelected[toolName]) {
          selectedCount += 1;
          // newToolName += nameMapping[toolName]
        }
      });
      if (e.checked) selectedCount--;
      else selectedCount++;
      if (selectedCount === 4) {
        document.querySelector(".all-checkbox").checked = true;
      } else {
        document.querySelector(".all-checkbox").checked = false;
      }

      if (!direct) {
        e.checked = !e.checked;
      }

      const refer = e.getAttribute("refer");

      if (e.checked) {
        const toolsObject = { ...toolsSelected };
        toolsObject[refer] = true;
        setToolsSelected(toolsObject);
      } else if (!e.checked) {
        const toolsObject = { ...toolsSelected };
        toolsObject[refer] = false;
        setToolsSelected(toolsObject);
      }
    }
  }

  // set all calculators active
  function setAll(e, direct) {
    if (fullPage) {
      if (!direct) {
        e.checked = !e.checked;
      }

      if (e.checked) {
        const checkboxes = document.querySelectorAll(".calculators-checkbox");
        checkboxes.forEach(box => {
          box.checked = true;
        });
        setToolsSelected({
          breakEvenCalculator: true,
          profitCalculator: true,
          pnlCalculator: true,
          netSaleCalculator: true,
        });
      } else {
        const checkboxes = document.querySelectorAll(".calculators-checkbox");
        checkboxes.forEach(box => {
          box.checked = false;
        });
        setToolsSelected({
          breakEvenCalculator: false,
          profitCalculator: false,
          pnlCalculator: false,
          netSaleCalculator: false,
        });
      }
    }
  }

  // give tool name based on active calculators
  function toolName() {
    if (fullPage) {
      let defaultToolName = "Select calculator";
      let newToolName = "";
      let selectedCount = 0;
      Object.keys(toolsSelected).forEach(toolName => {
        if (toolsSelected[toolName]) {
          selectedCount += 1;
          // newToolName += nameMapping[toolName]
        }
      });

      let nameMapping;

      if (selectedCount === 1) {
        nameMapping = {
          breakEvenCalculator: "BE calculator + ",
          profitCalculator: "Profit calculator + ",
          pnlCalculator: "P&L calculator + ",
          netSaleCalculator: "Net sale calculator + ",
        };
      } else if (selectedCount === 2) {
        nameMapping = {
          breakEvenCalculator: "BE calc + ",
          profitCalculator: "Prof. calc + ",
          pnlCalculator: "P&L calc + ",
          netSaleCalculator: "N.S. calc + ",
        };
      } else if (selectedCount === 3) {
        nameMapping = {
          breakEvenCalculator: "BE + ",
          profitCalculator: "Profit + ",
          pnlCalculator: "P&L + ",
          netSaleCalculator: "NS + ",
        };
      }

      Object.keys(toolsSelected).forEach(toolName => {
        if (toolsSelected[toolName] && selectedCount !== 4) {
          newToolName += nameMapping[toolName];
        }
      });

      if (selectedCount === 4) {
        newToolName = "All tools selected   ";
      }
      if (newToolName.length === 0) {
        document.querySelector(".tool-name").innerText = defaultToolName;
      } else {
        newToolName = newToolName.slice(0, -3);
        document.querySelector(".tool-name").innerText = newToolName;
      }
    }
  }
  useEffect(() => {
    toolName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolsSelected, fullPage]);

  useEffect(() => {
    if (dropdownActive) {
      setDisplayTxs(txs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownActive]);

  // give nft name based on nft selected
  function nftName(nft) {
    if (!nft) {
      return <>Select nft trade</>;
    } else {
      document.querySelector(
        ".nft-name"
      ).innerHTML = `<img src='${nft.image}' class="nft-image-select"/> <span class="nft-name-select">${nft.name}</span>`;
    }
  }
  useEffect(() => {
    document.querySelector(".nft-name").innerText = "Select nft trade";
  }, [blockchain]);

  // clear all calculators on click
  function clearAll() {
    for (let i = 1; i < 5; i++) {
      const num1 = document.querySelector(`#n1f${i}`);
      const num2 = document.querySelector(`#n2f${i}`);
      const num3 = document.querySelector(`#n3f${i}`);
      const num4 = document.querySelector(`#n4f${i}`);
      const num5 = document.querySelector(`#n5f${i}`);
      const num6 = document.querySelector(`#n6f${i}`);
      const result = document.querySelector(`#result${i}`);

      if (num1) num1.value = "";
      if (num2) num2.value = "";
      if (num3) num3.value = "";
      if (num4) num4.value = "";
      if (num5) num5.value = "";
      if (num6) num6.value = "";
      if (result) result.innerHTML = "";
    }
    setClearButton1Active(false);
    setClearButton2Active(false);
    setClearButton3Active(false);
    setClearButton4Active(false);
  }

  return (
    <>
      <div className="txs-container">
        {/* <button onClick={clearAll} className="clear-all-btn">Clear all</button> */}

        {fullPage && (
          <div className="calculators-nft">
            <p>Calculators: </p>
            <div className="calculators-select" onClick={e => arrow2(e)}>
              <div style={{ display: "flex", alignItems: "center" }} className="calculators-select-text-img">
                <p className="tool-name">{toolName}</p>
                <img src={dropdown} alt="" className="dropdown-img-2" />
              </div>

              {dropdown2Active && (
                <div className="calculators-container">
                  <div className="single-calculator-select">
                    <p onClick={e => setAll(e.target.nextElementSibling, false)}>All</p>
                    <input type="checkbox" onClick={e => setAll(e.target, true)} className="all-checkbox" />
                  </div>
                  <div className="single-calculator-select">
                    <p onClick={e => setCalculator(e.target.nextElementSibling, false)}>Break even calculator</p>
                    <input
                      type="checkbox"
                      onClick={e => setCalculator(e.target, true)}
                      defaultChecked={toolsSelected["breakEvenCalculator"]}
                      refer="breakEvenCalculator"
                      className="calculators-checkbox"
                    />
                  </div>
                  <div className="single-calculator-select">
                    <p onClick={e => setCalculator(e.target.nextElementSibling, false)}>Profit calculator</p>
                    <input
                      type="checkbox"
                      onClick={e => setCalculator(e.target, true)}
                      defaultChecked={toolsSelected["profitCalculator"]}
                      refer="profitCalculator"
                      className="calculators-checkbox"
                    />
                  </div>
                  <div className="single-calculator-select">
                    <p onClick={e => setCalculator(e.target.nextElementSibling, false)}>P&L calculator</p>
                    <input
                      type="checkbox"
                      onClick={e => setCalculator(e.target, true)}
                      defaultChecked={toolsSelected["pnlCalculator"]}
                      refer="pnlCalculator"
                      className="calculators-checkbox"
                    />
                  </div>
                  <div className="single-calculator-select">
                    <p onClick={e => setCalculator(e.target.nextElementSibling, false)}>Net sale calculator</p>
                    <input
                      type="checkbox"
                      onClick={e => setCalculator(e.target, true)}
                      defaultChecked={toolsSelected["netSaleCalculator"]}
                      refer="netSaleCalculator"
                      className="calculators-checkbox"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="txs-nft">
          <p>NFTs: </p>
          <div className="txs-select" onClick={e => arrow1(e)}>
            <div style={{ display: "flex", alignItems: "center" }} className="select-text-img">
              <p className="nft-name">{nftName(false)}</p>
              <img src={dropdown} alt="" className="dropdown-img" />
            </div>

            {dropdownActive && (
              <div className="transactions-container">
                {blockchain === "ethereum" && (
                  <EthereumTx
                    fullPage={fullPage}
                    singleToolSelected={singleToolSelected}
                    theme={theme}
                    clearAll={clearAll}
                    dropdownActive={dropdownActive}
                    displayTxs={displayTxs}
                    txs={txs}
                    setDisplayTxs={setDisplayTxs}
                    toolsSelected={toolsSelected}
                    singleTxActive={singleTxActive}
                    checkTheBox={checkTheBox}
                    nftName={nftName}
                    loading={loading}
                    setLoading={setLoading}
                    setClearButton1Active={setClearButton1Active}
                    setClearButton2Active={setClearButton2Active}
                    setClearButton3Active={setClearButton3Active}
                    setClearButton4Active={setClearButton4Active}
                  />
                )}
                {blockchain === "solana" && (
                  <SolanaConnect
                    useEffect={useEffect}
                    useState={useState}
                    solanaWallet={solanaWallet}
                    setSolanaWallet={setSolanaWallet}
                    solanaTrades={solanaTrades}
                    setSolanaTrades={setSolanaTrades}
                    nftName={nftName}
                    toolsSelected={toolsSelected}
                    setClearButton1Active={setClearButton1Active}
                    setClearButton2Active={setClearButton2Active}
                    setClearButton3Active={setClearButton3Active}
                    setClearButton4Active={setClearButton4Active}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const SolanaConnect = ({
  useEffect,
  solanaWallet,
  setSolanaWallet,
  setSolanaTrades,
  solanaTrades,
  nftName,
  toolsSelected,
  setClearButton1Active,
  setClearButton2Active,
  setClearButton3Active,
  setClearButton4Active,
}) => {
  // async function connectPhantom(){
  //     if ('phantom' in window) {
  //         const provider = window.phantom?.solana;

  //         if (provider?.isPhantom) {
  //             try{
  //                 await provider.connect();
  //                 setSolanaWallet(provider.publicKey.toString())
  //             }
  //             catch(e){
  //                 console.log(e)
  //             }
  //         }
  //     }

  // }

  useEffect(() => {
    async function fetchSolanaTxs() {
      let data = await fetch(
        `https://api-mainnet.magiceden.io/rpc/getGlobalActivitiesByQuery?q=%7B%22%24match%22%3A%7B%22%24or%22%3A%5B%7B%22seller_address%22%3A%22${solanaWallet}%22%7D%2C%7B%22buyer_address%22%3A%223ByoDw53FyA3ucFFzfjheRWfiScgkyACLMMxvDBApNgk%22%7D%5D%2C%22source%22%3A%7B%22%24nin%22%3A%5B%22yawww%22%5D%7D%7D%2C%22%24sort%22%3A%7B%22blockTime%22%3A-1%2C%22createdAt%22%3A-1%7D%2C%22%24skip%22%3A0%7D`
      );
      data = await data.json();
      data = data.results;
      const filteredTxs = data.filter(data => {
        data["blockTime"] = data.blockTime + "000";
        if (data.source === "opensea" && data.buyer_address === solanaWallet) {
          if (data.txType === "initializeEscrow") {
            return data;
          }
        } else {
          if (data.txName === "buy_now" && data.txType === "exchange" && data.buyer_address === solanaWallet) {
            return data;
          }
        }
        return false;
      });

      filteredTxs?.sort((a, b) => {
        return b.blockTime - a.blockTime;
      });

      setSolanaTrades(filteredTxs);
    }
    solanaWallet && !solanaTrades && fetchSolanaTxs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solanaWallet]);

  return (
    <>
      {
        !solanaWallet ? (
          <div
            className="connect-phantom-button"
            // onClick={()=> connectPhantom()}
          >
            In development...
            <img src={developing} alt="" className="developing-img" />
          </div>
        ) : solanaTrades ? (
          <div
            className="connect-phantom-button"
            // onClick={()=> connectPhantom()}
          >
            In development...
            <img src={developing} alt="" />
          </div>
        ) : (
          // <SolanaTx solanaTrades={solanaTrades} nftName={nftName} toolsSelected={toolsSelected} setClearButton1Active={setClearButton1Active} setClearButton2Active={setClearButton2Active} setClearButton3Active={setClearButton3Active} setClearButton4Active={setClearButton4Active}/>
          <div
            className="connect-phantom-button"
            // onClick={()=> connectPhantom()}
          >
            In development...
          </div>
        )
        // <p className="loading-trades">Loading trades...</p>
      }
    </>
  );
};

export default Txs;
