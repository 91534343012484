import React from "react";
// import baseUrl from '../baseUrl'
import wagmiLogoDark from "../../images/wagmiLogoDark.png";
import wagmiLogoLight from "../../images/wagmiLogoLight.png";

const LoadingHeader = React.memo(({ theme }) => {
  function logout() {
    localStorage.removeItem("jsonwebtoken");
    localStorage.removeItem("refreshWebToken");
    window.location.reload();
  }

  return (
    <>
      <div className={theme} id="header-dark">
        <header className="header">
          <img
            className="wagmi-title"
            src={theme === "dark" ? wagmiLogoDark : wagmiLogoLight}
            alt=""
            onClick={() => (window.location.href = "/")}
          />

          <h1 className="header-title">Profitability calculator</h1>

          <button className="connect" onClick={logout}>
            Log out
          </button>
        </header>
      </div>
    </>
  );
});

export default LoadingHeader;
