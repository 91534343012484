import React from "react";
import "./login.css";
import wagmiLogo from "../../images/wagmiLogoDark.png";
import SignMessage from "./SignMessage";
import instructionsLogo from "../../images/instructions.png";

import twitterImg from "../../images/twitter.svg";
import discordImg from "../../images/discord.svg";
import wwwImg from "../../images/www.svg";

import "@rainbow-me/rainbowkit/styles.css";
import { darkTheme, getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { infuraProvider } from "wagmi/providers/infura";
import { publicProvider } from "wagmi/providers/public";
import { infuraKey } from "../rpcNodes";

const Login = ({ setConnected }) => {
  // const [message, setMessage] = useState("Please connect your wallet to access the tool.")
  // const [loading, setLoading] = useState(false)

  const { chains, provider } = configureChains(
    [chain.mainnet],
    [infuraProvider({ apiKey: infuraKey }), publicProvider()]
  );

  const { connectors } = getDefaultWallets({
    appName: "My RainbowKit App",
    chains,
  });

  const wagmiClient = createClient({
    autoConnect: false,
    connectors,
    provider,
  });

  return (
    <>
      <div>
        <header className="header">
          <a href="https://wagmilabs.tools" rel="noreferrer" target={"_blank"}>
            <img className="wagmi-title-login" alt="" src={wagmiLogo} />{" "}
          </a>

          <h1 className="header-title-login">Profitability calculator</h1>

          <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider
              chains={chains}
              theme={darkTheme({
                overlayBlur: "small",
              })}
              coolMode
            >
              <SignMessage setConnected={setConnected} />
            </RainbowKitProvider>
          </WagmiConfig>
        </header>
      </div>

      <div>
        <div className="check-text-container">
          <p className="check-text">
            Please connect your wallet to access the NFT tool.
            {false && (
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
          </p>
        </div>

        <div className="login-general-links">
          <div className="login-general-links-line"></div>

          <a target="_blank" rel="noreferrer" href="https://wagmilabs.tools">
            <img alt="" src={wwwImg} className="login-general-links-img" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://twitter.com/wagmi_labs">
            <img alt="" src={twitterImg} className="login-general-links-img" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://discord.gg/PZsVtxdyQu">
            <img alt="" src={discordImg} className="login-general-links-img" />
          </a>

          <div className="login-general-links-line"></div>
        </div>

        <a className="legal-btn" href="/legal.html" target={"_blank"}>
          <img className="legal-img" alt="" src={instructionsLogo} />
          <div>Legal</div>
        </a>

        <a
          className="instructions-btn"
          rel="noreferrer"
          href="https://docs.wagmilabs.tools/profitcalc/"
          target={"_blank"}
        >
          <img className="instructions-img" alt="" src={instructionsLogo} />
          <div>instructions</div>
        </a>
      </div>
    </>
  );
};

export default Login;
