import React, { useEffect, useState } from "react";
import baseUrl from "../../baseUrl";
import Loading from "../Loading";

const Tool4 = ({
  blockchain,
  royalties,
  currencyType,
  ethPrice,
  solPrice,
  clearButtonActive,
  setClearButtonActive,
  fullPage,
  singleToolSelected,
  marketplace,
}) => {
  const [calc, setCalc] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const result = document.querySelector("#result4");
    const num1 = document.querySelector("#n1f4").value;
    const num2 = document.querySelector("#n2f4").value;

    if (calc > 0) {
      calculate();
    }
    async function calculate() {
      if (blockchain === "ethereum") {
        if (num1.length < 1 || num2.length < 1) {
          result.innerText = "Please fill all the fields.";
          setTimeout(() => (result.innerText = ""), 1000);
          return;
        }
      } else if (blockchain === "solana") {
        if (num1.length < 1 || num2.length < 1) {
          result.innerText = "Please fill all the fields.";
          setTimeout(() => (result.innerText = ""), 1000);
          return;
        }
      }

      const currency = currencyType === "usd" ? "$" : "€";

      if (blockchain === "ethereum") {
        const options = {
          num1,
          num2,
          royalties,
          ethPrice,
          solPrice,
          type: "ethereum",
        };

        try {
          setLoading(true);
          let calculationResult = await fetch(`${baseUrl}/api.wagmilabs/api/calculation4`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": localStorage.jsonwebtoken,
            },
            body: JSON.stringify(options),
          });
          calculationResult = await calculationResult.json();

          setLoading(false);
          if (calculationResult.authenticated === false) {
            result.innerHTML =
              "An error occurred while fetching data, please check your internet connection and retry.";
          } else {
            let calculation = calculationResult.calculation;
            let infiat = calculationResult.infiat;

            calculation = `<span class="eth-number">${calculation}</span>`;
            result.innerHTML = `If you list your NFT for ${num1} ETH, when it sells you will receive ${calculation} ETH (${currency} ${infiat})`;
          }
        } catch {
          result.innerHTML = "An error occurred while fetching data, please check your internet connection and retry.";
        }
      } else if (blockchain === "solana") {
        const options = {
          num1,
          num2,
          royalties,
          ethPrice,
          solPrice,
          type: "solana",
        };

        try {
          setLoading(true);
          let calculationResult = await fetch(`${baseUrl}/api.wagmilabs/api/calculation4`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": localStorage.jsonwebtoken,
            },
            body: JSON.stringify(options),
          });
          calculationResult = await calculationResult.json();

          setLoading(false);
          if (calculationResult.authenticated === false) {
            result.innerHTML =
              "An error occurred while fetching data, please check your internet connection and retry.";
          } else {
            let calculation = calculationResult.calculation;
            let infiat = calculationResult.infiat;

            calculation = `<span class="sol-number">${calculation}</span>`;
            result.innerHTML = `If you list your NFT for ${num1} SOL, when it sells you will receive ${calculation} SOL (${currency} ${infiat})`;
          }
        } catch {
          result.innerHTML = "An error occurred while fetching data, please check your internet connection and retry.";
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calc]);

  useEffect(() => {
    const result = document.querySelector("#result4");
    if (result.innerText.length > 10) {
      setCalc(calc + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplace, currencyType]);

  function updateClearButton() {
    const num1 = document.querySelector(`#n1f4`);
    const num2 = document.querySelector(`#n2f4`);
    const result = document.querySelector(`#result4`);

    if (num1.value.length > 0 || num2.value.length > 0 || result.innerText.length > 0) {
      setClearButtonActive(true);
    } else {
      setClearButtonActive(false);
    }
  }

  function clearTool() {
    document.querySelector(`#n1f4`).value = "";
    document.querySelector(`#n2f4`).value = "";
    document.querySelector(`#result4`).innerHTML = "";
  }

  const position = () => {
    if (fullPage) {
      return "inline";
    } else {
      if (singleToolSelected === "tool4") {
        return "flex";
      } else {
        return "none";
      }
    }
  };

  const margin = () => {
    if (fullPage) {
      return "0";
    } else {
      if (singleToolSelected === "tool4") {
        return "80px";
      } else {
        return "0";
      }
    }
  };

  return (
    <div className={fullPage ? "" : "align-calculator"} style={{ display: position(), marginLeft: margin() }}>
      <div>
        <div className="input-label-container">
          <h1 className="calc-title">Net sale amount calculator</h1>

          <div className="input-label-f3">
            <input
              className="input-f4 input-f4-1"
              type="number"
              id="n1f4"
              placeholder=" "
              onChange={updateClearButton}
            />
            <label htmlFor="n1f4" className="label-f4 label-f4-1">
              Listing price
            </label>
          </div>

          <div className="input-label-f3">
            <input
              className="input-f4 input-f4-2"
              type="number"
              id="n2f4"
              placeholder=" "
              onChange={updateClearButton}
            />
            <label htmlFor="n2f4" className="label-f4 label-f4-2">
              Creator fees
            </label>
          </div>
        </div>

        <div className="button-flex">
          <button id="button4" onClick={() => setCalc(calc + 1)}>
            CALCULATE
          </button>
          {clearButtonActive && (
            <button
              onClick={() => {
                clearTool();
                updateClearButton();
              }}
              className="clear-btn"
            >
              CLEAR
            </button>
          )}
        </div>

        <div className="result-name"> Result:</div>
        {loading && <Loading />}
        <div id="result4" className="calc-result4"></div>
      </div>
    </div>
  );
};

export default Tool4;
