import React, { useEffect } from "react";
import baseUrl from "../baseUrl";
import animationWhite from "./animationwhite.json";
import animationBlack from "./animationblack.json";
import Lottie from "react-lottie-player";

const Checking = React.memo(({ setConnected, setChecking, theme, modalLayout }) => {
  useEffect(() => {
    function delay(time) {
      return new Promise(resolve => {
        setTimeout(resolve, time);
      });
    }

    async function verify() {
      let res = await fetch(`${baseUrl}/wagmilabs/refresh`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refreshToken: localStorage.refreshWebToken, first: true }),
      });
      res = await res.json();

      await delay(modalLayout ? 0 : 1650);

      if (!res.authenticated) {
        localStorage.removeItem("jsonwebtoken");
        localStorage.removeItem("refreshWebToken");
        window.location.href = "/";
        setConnected(false);
      } else {
        localStorage.setItem("jsonwebtoken", res.token);
        setConnected(true);
        setChecking(false);
      }
    }
    setTimeout(verify, modalLayout ? 0 : 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return modalLayout ? (
    <></>
  ) : (
    <div className="checking-text" style={{ backgroundColor: theme === "light" ? "white" : "black" }}>
      <Lottie
        loop
        animationData={theme === "light" ? animationBlack : animationWhite}
        play
        style={{ width: "22vw", margin: "auto" }}
      />
    </div>
  );
});

export default Checking;
