import React, { useEffect, useState } from "react";
import Options from "./options/Options.js";
import Tool1 from "./tool1/Tool1.js";
import Tool2 from "./tool2/Tool2.js";
import Tool3 from "./tool3/Tool3.js";
import Tool4 from "./tool4/Tool4.js";
import "./tool.css";
import Txs from "./txs/Txs.js";
import breakEvenSvg from "../../images/selectTool/breakEvenSvg.svg";
import ProfitSvg from "../../images/selectTool/ProfitSvg.svg";
import pnlSvg from "../../images/selectTool/pnlSvg.svg";
import netSaleSvg from "../../images/selectTool/netSaleSvg.svg";

import instructionsLogo from "../../images/instructions.png";
// import Advertisement from "./Advertisement.js";

const toolMapping = {
  tool1: ".tool-selector-container2 :nth-child(1)",
  tool2: ".tool-selector-container2 :nth-child(2)",
  tool3: ".tool-selector-container2 :nth-child(3)",
  tool4: ".tool-selector-container2 :nth-child(4)",
};

const Tools = React.memo(
  ({
    setMarketplace,
    setBlockchain,
    marketplace,
    blockchain,
    theme,
    setTheme,
    royalties,
    setRoyalties,
    ethPrice,
    solPrice,
    currencyType,
    burger,
    setBurger,
    fullPage,
    setFullPage,
    singleToolSelected,
    setSingleToolSelected,
    modalLayout,
    setEthPrice,
    setCurrencyType,
  }) => {
    const [clearButton1Active, setClearButton1Active] = useState(false);
    const [clearButton2Active, setClearButton2Active] = useState(false);
    const [clearButton3Active, setClearButton3Active] = useState(false);
    const [clearButton4Active, setClearButton4Active] = useState(false);

    document.body.style.background =
      localStorage.theme === undefined
        ? theme === "dark"
          ? "#060b23"
          : "white"
        : localStorage.theme === "light"
        ? "white"
        : "#060b23";

    function activateTool(e, tool) {
      if (!modalLayout) {
        setSingleToolSelected(tool);

        document.querySelector(".tool-selector-image.active").classList.remove("active");

        if (e) {
          e.target.classList.add("active");

          const selectedInfo = e.target.getBoundingClientRect();
          const top = selectedInfo.top - 84 - (35 - 30 / 2) + "px";
          document.querySelector(".indicator").style.top = top;
        } else {
          document.querySelector(".hamburger").classList.toggle("is-active");
          const toolQuery = document.querySelector(toolMapping[tool]);
          toolQuery.classList.add("active");
          const selectedInfo = toolQuery.getBoundingClientRect();

          const top = selectedInfo.top - 84 - (35 - 30 / 2) + "px";
          document.querySelector(".indicator").style.top = top;

          setBurger(false);
        }
      }
    }

    return (
      <>
        <div className={theme} style={{ position: "relative" }}>
          {burger && (
            <div className="burger-menu-container">
              <div
                className="burger-tool-select"
                onClick={() => {
                  setSingleToolSelected("tool1");
                }}
              >
                Break Even Calc
              </div>
              <div
                className="burger-tool-select"
                onClick={() => {
                  setSingleToolSelected("tool2");
                }}
              >
                Desired Profit Calc
              </div>
              <div
                className="burger-tool-select"
                onClick={() => {
                  setSingleToolSelected("tool3");
                }}
              >
                P&L Calc
              </div>
              <div
                className="burger-tool-select"
                onClick={() => {
                  setSingleToolSelected("tool4");
                }}
              >
                Net Sale Calc
              </div>
            </div>
          )}

          {!fullPage && (
            <ToolSelector
              setSingleToolSelected={setSingleToolSelected}
              fullPage={fullPage}
              singleToolSelected={singleToolSelected}
              activateTool={activateTool}
              burger={burger}
              setBurger={setBurger}
              modalLayout={modalLayout}
            />
          )}

          <Options
            fullPage={fullPage}
            setFullPage={setFullPage}
            setMarketplace={setMarketplace}
            setBlockchain={setBlockchain}
            setRoyalties={setRoyalties}
            marketplace={marketplace}
            blockchain={blockchain}
            theme={theme}
            setTheme={setTheme}
            modalLayout={modalLayout}
            ethPrice={ethPrice}
            setEthPrice={setEthPrice}
            currencyType={currencyType}
            setCurrencyType={setCurrencyType}
          />
          <Txs
            singleToolSelected={singleToolSelected}
            fullPage={fullPage}
            blockchain={blockchain}
            setClearButton1Active={setClearButton1Active}
            setClearButton2Active={setClearButton2Active}
            setClearButton3Active={setClearButton3Active}
            setClearButton4Active={setClearButton4Active}
            theme={theme}
          />

          {/* {
        fullPage ?
        <>  */}
          <Tool1
            singleToolSelected={singleToolSelected}
            fullPage={fullPage}
            marketplace={marketplace}
            blockchain={blockchain}
            royalties={royalties}
            ethPrice={ethPrice}
            solPrice={solPrice}
            clearButtonActive={clearButton1Active}
            setClearButtonActive={setClearButton1Active}
          />
          <Tool2
            singleToolSelected={singleToolSelected}
            fullPage={fullPage}
            marketplace={marketplace}
            blockchain={blockchain}
            royalties={royalties}
            ethPrice={ethPrice}
            solPrice={solPrice}
            currencyType={currencyType}
            clearButtonActive={clearButton2Active}
            setClearButtonActive={setClearButton2Active}
          />
          <Tool3
            singleToolSelected={singleToolSelected}
            fullPage={fullPage}
            marketplace={marketplace}
            blockchain={blockchain}
            royalties={royalties}
            ethPrice={ethPrice}
            solPrice={solPrice}
            currencyType={currencyType}
            clearButtonActive={clearButton3Active}
            setClearButtonActive={setClearButton3Active}
          />
          <Tool4
            singleToolSelected={singleToolSelected}
            fullPage={fullPage}
            marketplace={marketplace}
            blockchain={blockchain}
            royalties={royalties}
            ethPrice={ethPrice}
            solPrice={solPrice}
            currencyType={currencyType}
            clearButtonActive={clearButton4Active}
            setClearButtonActive={setClearButton4Active}
          />
          {/* </>
        :
        <>
          {SelectedTool()}
        </>
      }
       */}

          {fullPage && (
            <a
              className="tools-instructions-btn"
              rel="noreferrer"
              href="https://docs.wagmilabs.tools/profitcalc/"
              target={"_blank"}
            >
              <img className="tools-instructions-img" alt="" src={instructionsLogo} />
              <div>Instructions</div>
            </a>
          )}
        </div>
      </>
    );
  }
);

export default Tools;

const ToolSelector = ({ setSingleToolSelected, fullPage, activateTool, setBurger, burger, modalLayout }) => {
  const [tab, setTab] = React.useState("tool1");
  useEffect(() => {
    setSingleToolSelected("tool1");
    positionSelector();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullPage]);

  function positionSelector() {
    if (window.innerWidth >= 650) {
      if (burger) setBurger(false);
    }

    const indicator = document.querySelector(".indicator");

    if (!modalLayout) {
      const top = (window.innerHeight - 84) / 8 - 35 + "px";
      indicator.style.top = top;
    }
  }

  function resizeSelector() {
    if (window.innerWidth >= 650) {
      if (burger) setBurger(false);
    }

    const selectedInfo = document.querySelector(".tool-selector-image.active").getBoundingClientRect();
    const indicator = document.querySelector(".indicator");
    const top = selectedInfo.top - 84 - (35 - 30 / 2) + "px";
    indicator.style.top = top;
  }

  window.addEventListener("resize", () => {
    if (!modalLayout) {
      resizeSelector();
    }
  });

  return (
    <>
      <div className="tool-selector-container">
        <div className="tool-selector-container2">
          {modalLayout ? (
            <>
              <b
                className={tab === "tool1" ? "active" : ""}
                onClick={() => {
                  setSingleToolSelected("tool1");
                  setTab("tool1");
                }}
              >
                Break Even
              </b>
              <b
                className={tab === "tool2" ? "active" : ""}
                onClick={() => {
                  setSingleToolSelected("tool2");
                  setTab("tool2");
                }}
              >
                Desired Profit
              </b>
              <b
                className={tab === "tool3" ? "active" : ""}
                onClick={() => {
                  setSingleToolSelected("tool3");
                  setTab("tool3");
                }}
              >
                P&L
              </b>
              <b
                className={tab === "tool4" ? "active" : ""}
                onClick={() => {
                  setSingleToolSelected("tool4");
                  setTab("tool4");
                }}
              >
                Net Sale
              </b>
            </>
          ) : (
            <>
              <img
                alt=""
                src={breakEvenSvg}
                onClick={e => activateTool(e, "tool1")}
                className="tool-selector-image active"
              />
              <img alt="" src={ProfitSvg} onClick={e => activateTool(e, "tool2")} className="tool-selector-image" />
              <img alt="" src={pnlSvg} onClick={e => activateTool(e, "tool3")} className="tool-selector-image" />
              <img alt="" src={netSaleSvg} onClick={e => activateTool(e, "tool4")} className="tool-selector-image" />
            </>
          )}
        </div>
        <div className="tool-selector-container3">
          <div className="indicator"></div>
        </div>
      </div>
    </>
  );
};
