import React, { useEffect, useState } from "react";
import baseUrl from "../../baseUrl";
import ClipboardJS from "clipboard";
import Loading from "../Loading";

const Tool2 = ({
  blockchain,
  solPrice,
  ethPrice,
  royalties,
  currencyType,
  clearButtonActive,
  setClearButtonActive,
  fullPage,
  singleToolSelected,
  marketplace,
}) => {
  const [calc, setCalc] = useState(0);
  const [profitState, setProfitState] = useState(0);
  const [profitType, setProfitType] = useState("ethereum");
  const [loading, setLoading] = useState(false);

  let resultText = `<p class="text-result">`;
  let resultTextClose = `</p>`;
  let copyHtml1 = `<div id="copyfield"> <span class="copyvalue" id="function2">`;
  let copyHtml2 = `</span> <span class="copy-btn" id="function2">Copy</span> </div>`;
  let copyHtml3 = `<div id="copyfield"> <span class="copyvalue-2" id="function2">`;
  let copyHtml4 = `</span> <span class="copy-btn-2" id="function2">Copy</span> </div>`;

  useEffect(() => {
    const profitType = document.querySelector("#profittype");
    const currentProfitTypeOptions = document.querySelectorAll("#profittype option");

    currentProfitTypeOptions.forEach(option => option.remove());

    const profitTypeEthOptions = {
      Ethereum: ["ethereum | Eth", "fiat | Fiat", "% | %"],
      Fiat: ["fiat | Fiat", "ethereum | Eth", "% | %"],
      "%": ["% | %", "ethereum | Eth", "fiat | Fiat"],
    };

    const profitTypeSolOptions = {
      Solana: ["solana | Solana", "fiat | Fiat", "% | %"],
      Fiat: ["fiat | Fiat", "solana | Solana", "% | %"],
      "%": ["% | %", "solana | Solana", "fiat | Fiat"],
    };

    let profitTypeOptions;

    if (blockchain === "ethereum") {
      if (localStorage.profitTypeEth === undefined) {
        profitTypeOptions = profitTypeEthOptions["Ethereum"];
      } else {
        profitTypeOptions = profitTypeSolOptions[localStorage.profitTypeEth];
      }
    } else if (blockchain === "solana") {
      if (localStorage.profitTypeSol === undefined) {
        profitTypeOptions = profitTypeSolOptions["Solana"];
      } else {
        profitTypeOptions = profitTypeSolOptions[localStorage.profitTypeSol];
      }
    }

    profitTypeOptions.forEach(option => {
      let pair = option.split("|");
      let newOption = document.createElement("option");
      newOption.value = pair[0];
      newOption.innerHTML = pair[1];
      profitType.options.add(newOption);
    });
  }, [blockchain]);

  function copyBtn(e, value) {
    e.target.setAttribute("data-clipboard-text", value);
    if (e.target.classList.contains("copy-btn-2")) {
      new ClipboardJS(`.copy-btn-2[id="function2"]`);
    } else {
      new ClipboardJS(`.copy-btn[id="function2"]`);
    }

    e.target.innerText = "Copied";
    setTimeout(() => (e.target.innerText = "Copy"), 1000);
  }

  useEffect(() => {
    const result = document.querySelector("#result2");
    const num1 = document.querySelector("#n1f2").value;
    const num2 = document.querySelector("#n2f2").value;
    const num3 = document.querySelector("#n3f2").value;
    const num4 = document.querySelector("#n4f2").value;
    const num5 = document.querySelector("#n5f2").value;
    const num6 = document.querySelector("#n6f2").value;

    if (calc > 0) {
      calculate();
    }
    async function calculate() {
      if (blockchain === "ethereum") {
        if (
          num1.length < 1 ||
          num2.length < 1 ||
          num3.length < 1 ||
          num4.length < 1 ||
          num5.length < 1 ||
          num6.length < 1
        ) {
          result.innerText = "Please fill all the fields.";
          setTimeout(() => (result.innerText = ""), 1000);
          return;
        }
      } else if (blockchain === "solana") {
        if (num1.length < 1 || num2.length < 1 || num5.length < 1 || num6.length < 1) {
          result.innerText = "Please fill all the fields.";
          setTimeout(() => (result.innerText = ""), 1000);
          return;
        }
      }

      const currency = currencyType === "usd" ? "$" : "€";

      if (blockchain === "ethereum") {
        try {
          setLoading(true);
          const options = {
            num1,
            num2,
            num3,
            num4,
            num5,
            num6,
            royalties,
            solPrice,
            ethPrice,
            type: "ethereum",
            profitType,
          };

          let calculationResult = await fetch(`${baseUrl}/api.wagmilabs/api/calculation2`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": localStorage.jsonwebtoken,
            },
            body: JSON.stringify(options),
          });
          calculationResult = await calculationResult.json();

          setLoading(false);
          if (calculationResult.authenticated === false) {
            result.innerHTML =
              "An error occurred while fetching data, please check your internet connection and retry.";
          } else {
            const copyValue = calculationResult.calculation;
            const infiat = calculationResult.calculationInFiat;
            let calculation = calculationResult.calculation;

            if (num1 <= 1) {
              calculation = `<span class="eth-number">${calculation}</span>`;
              result.innerHTML = `${resultText}To reach desired profit you need to list your NFT at ${calculation} ETH (${currency} ${infiat}) ${resultTextClose}${copyHtml1}${calculation}${copyHtml2}`;

              const copyBtnElement = document.querySelector(`.copy-btn[id="function2"]`);
              copyBtnElement.addEventListener("click", e => copyBtn(e, copyValue));
            } else if (num1 > 1) {
              let otherresult = calculation / num1;
              calculation = `<span class="eth-number">${calculation}</span>`;
              const copyValue2 = Math.ceil(otherresult * 100000) / 100000.0;
              otherresult = `<span class="eth-number">${Math.ceil(otherresult * 100000) / 100000.0}</span>`;

              result.innerHTML = `${resultText}To reach desired profit you need to list EACH of your ${num1} NFTS at ${otherresult} ETH (${currency} ${Math.round(
                infiat / num1
              )}), or list them in a bundle at ${calculation} ETH (${currency} ${infiat})${resultTextClose}${copyHtml1}${otherresult}${copyHtml2}${copyHtml3}${calculation}${copyHtml4}`;

              const copyBtnElement = document.querySelector(`.copy-btn[id="function2"]`);
              const copyBtn2Element = document.querySelector(`.copy-btn-2[id="function2"]`);
              copyBtnElement.addEventListener("click", e => copyBtn(e, copyValue2));
              copyBtn2Element.addEventListener("click", e => copyBtn(e, copyValue));
            }
          }
        } catch {
          result.innerHTML = "An error occurred while fetching data, please check your internet connection and retry.";
        }
      } else if (blockchain === "solana") {
        try {
          setLoading(true);
          const options = {
            num1,
            num2,
            num5,
            num6,
            solPrice,
            ethPrice,
            royalties,
            type: "solana",
            profitType,
          };

          let calculationResult = await fetch(`${baseUrl}/api.wagmilabs/api/calculation2`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": localStorage.jsonwebtoken,
            },
            body: JSON.stringify(options),
          });
          calculationResult = await calculationResult.json();

          setLoading(false);
          if (calculationResult.authenticated === false) {
            result.innerHTML =
              "An error occurred while fetching data, please check your internet connection and retry.";
          } else {
            const copyValue = calculationResult.calculation;
            const infiat = calculationResult.calculationInFiat;
            let calculation = calculationResult.calculation;

            if (num1 <= 1) {
              calculation = `<span class="sol-number">${calculation}</span>`;
              result.innerHTML = `${resultText}To reach desired profit you need to list your NFT at ${calculation} SOL (${currency} ${infiat})${resultTextClose}${copyHtml1}${calculation}${copyHtml2}`;

              const copyBtnElement = document.querySelector(`.copy-btn[id="function2"]`);
              copyBtnElement.addEventListener("click", e => copyBtn(e, copyValue));
            } else if (num1 > 1) {
              let otherresult = calculation / num1;
              calculation = `<span class="sol-number">${calculation}</span>`;
              const copyValue2 = Math.ceil(otherresult * 100000) / 100000.0;
              otherresult = `<span class="sol-number">${Math.ceil(otherresult * 100000) / 100000.0}</span>`;

              result.innerHTML = `${resultText}To reach desired profit you need to list EACH of your ${num1} NFTS at ${otherresult} SOL (${currency} ${Math.round(
                infiat / num1
              )}), or list them in a bundle at ${calculation} SOL (${currency} ${infiat})${resultTextClose}${copyHtml1}${otherresult}${copyHtml2}${copyHtml3}${calculation}${copyHtml4}`;

              const copyBtnElement = document.querySelector(`.copy-btn[id="function2"]`);
              const copyBtn2Element = document.querySelector(`.copy-btn-2[id="function2"]`);
              copyBtnElement.addEventListener("click", e => copyBtn(e, copyValue2));
              copyBtn2Element.addEventListener("click", e => copyBtn(e, copyValue));
            }
          }
        } catch {
          result.innerHTML = "An error occurred while fetching data, please check your internet connection and retry.";
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calc]);

  useEffect(() => {
    const result = document.querySelector("#result2");
    if (result.innerText.length > 10) {
      setCalc(calc + 1);
    }
    if (blockchain === "ethereum") {
      document.querySelector(".input-label-f2-hide").style.display = "inline";
      document.querySelector(".input-label-f2-hide2").style.display = "inline";
    } else if (blockchain === "solana") {
      document.querySelector(".input-label-f2-hide").style.display = "none";
      document.querySelector(".input-label-f2-hide2").style.display = "none";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplace, profitType, currencyType]);

  useEffect(() => {
    setProfitType(
      document
        .querySelector("#profittype")
        .options[document.querySelector("#profittype").selectedIndex].value.replace(" ", "")
    );
  }, [blockchain, profitState]);

  function updateClearButton() {
    const num1 = document.querySelector(`#n1f2`);
    const num2 = document.querySelector(`#n2f2`);
    const num3 = document.querySelector(`#n3f2`);
    const num4 = document.querySelector(`#n4f2`);
    const num5 = document.querySelector(`#n5f2`);
    const result = document.querySelector(`#result2`);

    if (
      num1.value.length > 0 ||
      num2.value.length > 0 ||
      num3.value.length > 0 ||
      num4.value.length > 0 ||
      num5.value.length > 0 ||
      result.innerText.length > 0
    ) {
      setClearButtonActive(true);
    } else {
      setClearButtonActive(false);
    }
  }

  function clearTool() {
    document.querySelector(`#n1f2`).value = "";
    document.querySelector(`#n2f2`).value = "";
    document.querySelector(`#n3f2`).value = "";
    document.querySelector(`#n4f2`).value = "";
    document.querySelector(`#n5f2`).value = "";
    document.querySelector(`#n6f2`).value = "";
    document.querySelector(`#result2`).innerHTML = "";
  }

  const position = () => {
    if (fullPage) {
      return "inline";
    } else {
      if (singleToolSelected === "tool2") {
        return "flex";
      } else {
        return "none";
      }
    }
  };

  const margin = () => {
    if (fullPage) {
      return "0";
    } else {
      if (singleToolSelected === "tool2") {
        return "80px";
      } else {
        return "0";
      }
    }
  };

  return (
    <div className={fullPage ? "" : "align-calculator"} style={{ display: position(), marginLeft: margin() }}>
      <div>
        <div className="input-label-container">
          <h1 className="calc-title">Desired profit calculator</h1>

          <div className="input-label-f2">
            <input
              className="input-f2 input-f2-1"
              type="number"
              id="n1f2"
              placeholder=" "
              onChange={updateClearButton}
            />
            <label htmlFor="n1f2" className="label-f2 label-f2-1">
              N° of NFTs purchased
            </label>
          </div>

          <div className="input-label-f2">
            <input
              className="input-f2 input-f2-2"
              type="number"
              id="n2f2"
              placeholder=" "
              onChange={updateClearButton}
            />
            <label htmlFor="n2f2" className="label-f2 label-f2-2">
              Individual NFT cost
            </label>
          </div>

          <div className="input-label-f2-hide">
            <input
              className="input-f2 input-f2-3"
              type="number"
              id="n3f2"
              placeholder=" "
              onChange={updateClearButton}
            />
            <label htmlFor="n3f2" className="label-f2 label-f2-3">
              Gas fees (total)
            </label>
          </div>

          <div className="input-label-f2-hide2">
            <input
              className="input-f2 input-f2-4"
              type="number"
              id="n4f2"
              placeholder=" "
              onChange={updateClearButton}
            />
            <label htmlFor="n4f2" className="label-f2 label-f2-4">
              Approval cost
            </label>
          </div>

          <div className="input-label-f2">
            <input
              className="input-f2 input-f2-5"
              type="number"
              id="n5f2"
              placeholder=" "
              onChange={updateClearButton}
            />
            <label htmlFor="n5f2" className="label-f2 label-f2-5">
              Creator fees
            </label>
          </div>

          <div style={{ display: "inline-block" }}>
            <div className="input-label-f2">
              <input
                className="input-f2 input-f2-6"
                type="number"
                id="n6f2"
                placeholder=" "
                onChange={updateClearButton}
              />
              <label htmlFor="n6f2" className="label-f2 label-f2-6">
                Desired profit amount
              </label>
            </div>

            <span className="in">in: </span>
            <select name="profittype" id="profittype" onChange={() => setProfitState(profitState + 1)}></select>
          </div>
        </div>

        <div className="button-flex">
          <button id="button2" onClick={() => setCalc(calc + 1)}>
            CALCULATE
          </button>
          {clearButtonActive && (
            <button
              onClick={() => {
                clearTool();
                updateClearButton();
              }}
              className="clear-btn"
            >
              CLEAR
            </button>
          )}
        </div>

        <div className="result-name"> Result:</div>
        {loading && <Loading />}
        <div id="result2" className="calc-result2"></div>
      </div>
    </div>
  );
};

export default Tool2;
