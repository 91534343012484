import React, { useEffect } from "react";
import { useAccount } from "wagmi";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import baseUrl from "../baseUrl";

const SignMessage = ({ setConnected }) => {
  const { address, isConnected } = useAccount();

  useEffect(() => {
    if (isConnected === true) {
      login();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  async function login() {
    const messageElement = document.querySelector(".check-text");
    messageElement.innerText = "Logging in...";

    const rawConnectionMethod = localStorage.getItem("rk-recent");

    let connectionMethod = "undefined";
    if (rawConnectionMethod) {
      const parsedConnections = JSON.parse(rawConnectionMethod);
      connectionMethod = parsedConnections[0];
    }

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ address, connectionMethod }),
    };
    let res = await fetch(`${baseUrl}/api.wagmilabs/api/login`, options);
    res = await res.json();
    if (res.authenticated) {
      localStorage.setItem("jsonwebtoken", res.token);
      localStorage.setItem("refreshWebToken", res.refreshToken);
      setConnected(true);
    } else {
      messageElement.innerText = res.message;
    }
  }

  return (
    <>
      <ConnectButton.Custom>
        {({ account, chain, openAccountModal, openChainModal, openConnectModal, authenticationStatus, mounted }) => {
          // Note: If your app doesn't use authentication, you
          // can remove all 'authenticationStatus' checks
          const ready = mounted && authenticationStatus !== "loading";
          const connected =
            ready && account && chain && (!authenticationStatus || authenticationStatus === "authenticated");

          return (
            <div
              {...(!ready && {
                "aria-hidden": true,
                style: {
                  opacity: 0,
                  pointerEvents: "none",
                  userSelect: "none",
                },
              })}
            >
              {(() => {
                if (!connected) {
                  return (
                    <button onClick={openConnectModal} type="button" className="connect-button">
                      Connect Wallet
                    </button>
                  );
                }

                if (chain.unsupported) {
                  return (
                    <button onClick={openChainModal} type="button" className="connect-button">
                      Wrong network
                    </button>
                  );
                }

                return (
                  <div style={{ display: "flex", gap: 12 }}>
                    <button onClick={openAccountModal} type="button" className="connect-button">
                      {account.displayName}
                    </button>
                  </div>
                );
              })()}
            </div>
          );
        }}
      </ConnectButton.Custom>
    </>
  );
};

export default SignMessage;
